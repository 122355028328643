import jwt_decode from "jwt-decode";
import axios from 'axios';
import moment from 'moment';

const API = process.env.REACT_APP_AURIS_API_URL;
//const API = "http://localhost:8080/api"

export const getUser = () => {
    let email = getJWTEmail();
    if(!email) {
        return '';
    }

    const user = {
        email: email
    };

    return axios.get(`${API}/user`, {headers: {"authorization":localStorage.getItem('auth-token')}}, {user});  
}

export const getJWTEmail = () => {
    if(!localStorage.getItem('auth-token')) {
        return '';
    }
    const decode = jwt_decode(localStorage.getItem('auth-token'));
    return decode.email;
}

export const translateAccents = (accent) => {
    const accents = {
        'argentinian': 'Argentina',
        'bolivian': 'Bolivia',
        'chilean': 'Chile',
        'costa rican': 'Costa Rica',
        'cuban': 'Cuba',
        'dominican republic': 'República Dominicana',
        'ecuadorian': 'Ecuador',
        'equatorial guinean': 'Guinea Ecuatorial',
        'guatemalan': 'Guatemala',
        'honduran': 'Honduras',
        'puerto rican': 'Puerto Rico',
        'latin american': 'América Latina',
        'mexican': 'México',
        'nicaraguan': 'Nicaragua',
        'panamanian': 'Panamá',
        'peruvian': 'Perú',
        'paraguayan': 'Paraguay',
        'salvadorean': 'El Salvador',
        'american': 'América',
        'north american': 'EEUU',
        'uruguayan': 'Uruguay',
        'venezuelan': 'Venezuela',
        'spanish': 'España',
        'colombian': 'Colombia',
        'british': 'Ingles',
        'french': 'Francia',
        'german': 'Alemán',
        'portuguese': 'Portugués',
        'catalan': 'Catalán'
    }

    return accents[accent ? accent.toLowerCase() : ''];
}

export const getCountryEmoji = (country) => {

    const accents = {
        'argentinian': '🇦🇷',
        'bolivian': '🇧🇴',
        'chilean': '🇨🇱',
        'costa rican': '🇨🇷',
        'cuban': '🇨🇺',
        'dominican republic': '🇩🇴',
        'ecuadorian': '🇪🇨',
        'equatorial guinean': '🇬🇶',
        'guatemalan': '🇬🇹',
        'honduran': '🇭🇳',
        'puerto rican': '🇵🇷',
        'latin american': '🌎',
        'mexican': '🇲🇽',
        'nicaraguan': '🇳🇮',
        'panamanian': '🇵🇦',
        'peruvian': '🇵🇪',
        'paraguayan': '🇵🇾',
        'salvadorean': '🇸🇻',
        'american': '🌎',
        'north american': '🇺🇸',
        'uruguayan': '🇺🇾',
        'venezuelan': '🇻🇪',
        'spanish': '🇪🇸',
        'colombian': '🇨🇴',
        'catalan': 'ES-CT',
        'portuguese': '🇵🇹',
    }
    return accents[country ? country.toLowerCase() : ''];
}

export const getToday = () => {
    return { startDate: new Date(moment().startOf('day')), endDate: new Date(moment().endOf('day')) }
}

export const getYesterday = () => {
    return { startDate: new Date(moment().subtract(1, 'days').startOf('day')), endDate: new Date(moment().subtract(1, 'days').endOf('day')) }
}

export const getLastWeek = () => {
    return { startDate: new Date(moment().subtract(6, 'days').startOf('day')), endDate: new Date(moment().endOf('day')) }
}

export const getLastMonth = () => {
    return { startDate: new Date(moment().subtract(1, 'months').startOf('day')), endDate: new Date(moment().endOf('day')) }
}

export const getLastYear = () => {
    return { startDate: new Date(moment().subtract(1, 'years').startOf('day')), endDate: new Date(moment().endOf('day')) }
}